import { get } from '@/utils/http';
import qs from 'qs'

export const getCategories = locale => {
    let query = qs.stringify({
        populate: ['projects', 'localizations'],
        locale: locale,
        pagination: {
            page: 1,
            pageSize: 100,
        }
    }, {
        encodeValuesOnly: true,
    })
    return get(`categories?${query}`);
}

export const getCategoryById = (id, locale) => {
    let query = qs.stringify({
        populate: ['projects.images', 'projects.localizations', 'localizations'],
        locale: locale
    }, {
        encodeValuesOnly: true,
    })

    return get(`categories/${id}?${query}`)
}