<template>
	<div id="app">
		<div id="nav">
			<b-navbar fixed-top wrapper-class centered spaced :mobile-burger="true" type="is-mainblue">
				<template #brand>
					<!--<b-navbar-item class="logo-fv" tag="router-link" :to="{ path: '/' }">
						<img src="./assets/logo.svg" alt="Formato Verde" />
					</b-navbar-item>-->
					<b-navbar-item class="logo-fv" @click="goToHomePage()">
						<img src="./assets/logo.svg" alt="Formato Verde" />
					</b-navbar-item>
				</template>

				<template #end>
					<b-navbar-item class="mr-1" :class="{ 'is-green-active': isMobile }" id="skills-nav" tag="router-link" :to="{ path: '/skills' }">
						{{ $t('components.Navbar.skills') }}
					</b-navbar-item>
					<b-navbar-item class="mr-1" :class="{ 'is-green-active': isMobile }" id="work-nav" tag="router-link" :to="{ path: '/work' }">
						{{ $t('components.Navbar.work') }}
					</b-navbar-item>
					<b-navbar-item class="mr-1" :class="{ 'is-green-active': isMobile }" id="culture-nav" tag="router-link" :to="{ path: '/culture' }">
						{{ $t('components.Navbar.culture') }}
					</b-navbar-item>

					<b-navbar-item class="mr-1 language-dropdown">
						<b-dropdown @change="switchLocale" v-model="$i18n.locale" :triggers="['click']" aria-role="list">
							<template #trigger>
								<b-button :label="$i18n.locale" type="is-primary" icon-right="menu-down" />
							</template>

							<b-dropdown-item value="pt" aria-role="listitem">pt</b-dropdown-item>
							<b-dropdown-item value="en" aria-role="listitem">en</b-dropdown-item>
						</b-dropdown>
					</b-navbar-item>
				</template>
			</b-navbar>
		</div>
		<router-view :key="$route.fullPath" />
		<footer class="footer">
			<div class="content has-text-centered">
				<p>© Formato Verde 2022</p>
			</div>
		</footer>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	data() {
		return {
			isMobile: window.innerWidth <= 1023,
			language: this.$i18n.locale,
		};
	},
	methods: {
		goToHomePage() {
			if (this.$route.name != 'Home') {
				this.$router.push({
					path: '/',
				});
			} else {
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}
		},
		switchLocale(locale) {
			this.$i18n.locale = locale;
			/*if (this.$i18n.locale !== locale) {
				this.$i18n.locale = locale;
			}*/
		},
	},
	created() {
		// IP DETECTION TO SET LANGUAGE OF WEBSITE
		let ip_country = localStorage.getItem('client_country');
		if (!ip_country) {
			axios
				.get(process.env.VUE_APP_IP_DETECTOR_URL)
				.then((response) => {
					if (response.data && response.data.countryCode) {
						this.$i18n.locale = response.data.countryCode == 'PT' ? 'pt' : 'en';
						localStorage.setItem('client_country', this.$i18n.locale);
						this.$emit('import')
					}
				})
				.catch((error) => {
					this.$i18n.locale = 'en';
					localStorage.setItem('client_country', this.$i18n.locale);
					console.log(error);
					this.$emit('import')
				});
		} else {
			this.$i18n.locale = ip_country;
		}
	},
};
</script>

<style lang="scss">
@import './assets/scss/app.scss';
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*/

#nav * {
	color: $grey-light;
}

.footer {
	background-color: $footer-background-color !important;
	padding: $footer-padding;

	/*position: fixed;
	width: 100%;
	bottom: 0;*/
}

.footer p {
	color: $grey-light;
	font-size: 85%;
}

.navbar-menu #skills-nav.router-link-active,
.navbar-menu #skills-nav:hover {
	border: 1px solid $green;
	border-radius: 0px;
}

.navbar-menu #work-nav.router-link-active,
.navbar-menu #work-nav:hover {
	border: 1px solid $red;
	border-radius: 0px;
}

.navbar-menu #culture-nav.router-link-active,
.navbar-menu #culture-nav:hover {
	border: 1px solid $blue;
	border-radius: 0px;
}

.navbar-menu .is-green-active.router-link-active {
	border: none !important;
	background-color: $green;
	color: $black !important;
}

.navbar.is-mainblue .navbar-brand > a.navbar-item:focus,
.navbar.is-mainblue .navbar-brand > a.navbar-item:hover,
.navbar.is-mainblue .navbar-brand > a.navbar-item.is-active,
.navbar.is-mainblue .navbar-brand .navbar-link:focus,
.navbar.is-mainblue .navbar-brand .navbar-link:hover,
.navbar.is-mainblue .navbar-brand .navbar-link.is-active {
	background-color: transparent !important;
}

.navbar.is-mainblue .navbar-start > a.navbar-item:focus,
.navbar.is-mainblue .navbar-start > a.navbar-item:hover,
.navbar.is-mainblue .navbar-start > a.navbar-item.is-active,
.navbar.is-mainblue .navbar-start .navbar-link:focus,
.navbar.is-mainblue .navbar-start .navbar-link:hover,
.navbar.is-mainblue .navbar-start .navbar-link.is-active,
.navbar.is-mainblue .navbar-end > a.navbar-item:focus,
.navbar.is-mainblue .navbar-end > a.navbar-item:hover,
.navbar.is-mainblue .navbar-end > a.navbar-item.is-active,
.navbar.is-mainblue .navbar-end .navbar-link:focus,
.navbar.is-mainblue .navbar-end .navbar-link:hover,
.navbar.is-mainblue .navbar-end .navbar-link.is-active {
	background-color: transparent !important;
}

@media only screen and (min-width: 1024px) {
	.footer {
		position: fixed !important;
		width: 100% !important;
		bottom: 0 !important;
	}

	.navbar-brand {
		padding-left: 15%;
	}

	.navbar-menu .navbar-end {
		padding-right: 19%;
	}
}

@media only screen and (max-width: 1023px) {
	.navbar-menu.is-active {
		background-color: #06072af2 !important;
	}
}

.language-dropdown {
	background-color: transparent !important;
}

</style>
