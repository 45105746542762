<template>
	<div>
		<div class="columns is-mobile is-vcentered mt-6">
			<div class="column is-10-desktop is-offset-2-desktop is-12-touch">
				<div class="column" :class="{'pl-0 pr-0' : !isMobile}">
					<h2 class="has-text-black has-text-weight-light is-size-4 title-custom-underline mb-6">
						<span class="has-text-weight-bold is-size-3">{{ title }} </span> {{ subtitle }}
					</h2>

					<b-carousel-list v-model="test" :data="projects" :items-to-show="isMobile ? 1.5 : 2.5">
						<template #item="list">
							<div @click="openProject(list.id)" class="is-clickable">
								<figure class="image is-5by4 is-2by1-touch" :class="{ 'min-height-mobile': isMobile }">
									<!--<a @click="info(list.id)"
									>--><img :src="list.attributes.images.data ? `${api_url}${list.attributes.images.data[0].attributes.formats.small.url}` : default_image" />
									<!--</a>-->
								</figure>

								<div class="project-info ml-6">
									<span class="left-divider"></span>
									<div class=" reduce-margin-top">
										<p class="title-year mb-2 has-text-weight-light">{{ list.attributes.title  }}, {{ list.attributes.year }}</p>
										<p class="has-text-weight-bold has-text-black mb-2 is-size-6">{{ list.attributes.short_description }}</p>
										<p class="has-text-grey has-text-weight-light mb-2 is-size-6">{{  list.attributes.hashtags }}</p>
									</div>
								</div>
							</div>
						</template>
					</b-carousel-list>
				</div>
			</div>
		</div>
		<!--<div class="mt-5 bottom-link-div">
			<p class="is-clickable" @click="emitToParent()">see more projects</p>
		</div>-->
		<br />
		<div class="columns mt-1 mb-6">
			<div class="column is-2 is-offset-8 pr-5" style="text-align: right">
				<div class="bottom-link-div is-clickable">
					<p class="is-clickable" @click="emitToParent()">{{link_text}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		projects: {
			type: Array,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		subtitle: {
			type: String,
			required: true,
		},
		link_text: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			api_url: process.env.VUE_APP_API_HOST,
			default_image: 'https://buefy.org/static/img/placeholder-1280x960.png',
			test: 0,
			isMobile: window.innerWidth <= 1023,
		};
	},
	methods: {
		info(value) {
			this.test = value;
		},
		emitToParent() {
			this.$emit('childEmit');
		},
		openProject(id) {
			this.$emit('childEmitOpenProject', id);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/_variables.scss';

figure img {
	object-fit: cover;
}

@media only screen and (min-width: 1024px) {
	section {
		min-height: 70vh;
		//background-color: $white;
	}
}

.title-custom-underline::after {
	content: '';
	display: block;
	width: 3rem;
	padding-top: 0.5%;
	border-bottom: 0.3rem solid $lime;
}

/*.bottom-link-div {
	position: absolute;
	bottom: 20px;
	right: 90px;
}*/

.bottom-link-div * {
	color: $black;
}

.bottom-link-div p::before {
	display: inline-block;
	content: '';
	border-top: 1px solid $black;
	width: 4rem;
	margin: 0 1rem;
	transform: translateY(-4px);
}

.carousel-list.has-shadow {
	box-shadow: none;
}

.title-year {
	font-size: 15px;
}

.left-divider {
	display: inline-block;
	width: 0;
	min-height: 8em;
	transform: translateY(-3rem);
	border-left: 0.2rem solid $lime;
	//border-right: 0.2rem solid $lime;
}



.reduce-margin-top {
	transform: translateY(-7rem);
	margin-left: 2rem;
}

.min-height-mobile {
	min-height: 34vh;
}
</style>