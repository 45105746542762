import { get } from '@/utils/http';
import qs from 'qs'

export const getProjects = (locale) => {
    let query = qs.stringify({
        populate: ['images', 'category', 'localizations'],
        locale: locale,
        pagination: {
            page: 1,
            pageSize: 100,
        }
    }, {
        encodeValuesOnly: true,
    })
    return get(`projects?${query}`);
}

export const getProjectById = ( id, locale ) => {
    let query = qs.stringify({
        populate: ['images', 'category.localizations', 'localizations'],
        locale: locale
    },  {
        encodeValuesOnly: true,
    })
    return get(`projects/${id}?${query}`)
}

export const getProjectsOnWokPage = ( locale ) => {
    let query = qs.stringify({
        populate: ['images', 'category', 'localizations'],
        locale: locale,
        pagination: {
            page: 1,
            pageSize: 100,
        },
        filters: {
            on_work_page: {
                $eq: true
            }
        }
    })

    return get(`projects?${query}`);
}