var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"has-background-primary"},[_c('div',{staticClass:"home",class:{ 'vertically-centered ': _vm.isMobile }},[_c('div',{staticClass:"is-mobile columns is-vcentered"},[_c('div',{staticClass:"column is-2 is-hidden-touch",staticStyle:{"text-align":"center"}},[(_vm.socialNetworks)?_c('div',{staticClass:"social_networks ml-6"},[_vm._l((_vm.socialNetworks),function(social){return _c('div',{key:social.id,staticClass:"columns"},[_c('div',{staticClass:"column is-4 is-clickable",on:{"click":function($event){return _vm.openSocialNetwork(social.attributes.redirect_url)}}},[_c('b-icon',{attrs:{"icon":social.attributes.material_design_icon_alias,"size":"is-small","type":"is-grey-light"}})],1)])}),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4 is-clickable",on:{"click":function($event){return _vm.shareWebsite()}}},[_c('b-icon',{attrs:{"icon":"share","size":"is-small","type":"is-grey-light"}})],1)])],2):_vm._e()]),_c('div',{staticClass:"column is-8-desktop is-12-touch"},[_c('div',{staticClass:"column is-offset-2-desktop has-text-right"},[(_vm.$i18n.locale == 'en')?_c('figure',{staticClass:"back_figure"},[_c('img',{attrs:{"src":_vm.back_image_en}})]):_vm._e(),(_vm.$i18n.locale == 'pt')?_c('figure',{staticClass:"back_figure"},[_c('img',{attrs:{"src":_vm.back_image_pt}})]):_vm._e()]),_c('div',{staticClass:"column info-div",class:{ 'pl-0 pr-0': !_vm.isMobile }},[_c('h1',{staticClass:"info breakable-text"},[_vm._v(" "+_vm._s(_vm.front_title)+" "),_c('span',{style:({
								'border-color': _vm.secondary_color,
								'text-decoration': 'underline',
								'text-decoration-color': _vm.secondary_color,
								'text-decoration-style': 'solid',
								'text-decoration-thickness': '0.3rem',
								'text-underline-offset': '4px',
							})},[_vm._v(_vm._s(_vm.underline_text))]),_vm._v(". ")]),(_vm.description)?_c('div',{staticClass:"mt-6"},[_c('h2',{staticClass:"has-text-grey-light has-text-weight-light info-bottom"},[_vm._v(_vm._s(_vm.description))])]):_vm._e(),_c('div',{staticClass:"columns is-mobile is-hidden-desktop mt-6"},[_c('div',{staticClass:"column is-half is-offset-one-quarter"},[_c('div',{staticClass:"columns has-text-centered is-mobile"},[_vm._l((_vm.socialNetworks),function(social){return _c('div',{key:social.id,staticClass:"column is-clickable",on:{"click":function($event){return _vm.openSocialNetwork(social.attributes.redirect_url)}}},[_c('b-icon',{attrs:{"icon":social.attributes.material_design_icon_alias,"size":"is-small","type":"is-grey-light"}})],1)}),_c('div',{staticClass:"column is-clickable",on:{"click":function($event){return _vm.shareWebsite()}}},[_c('b-icon',{attrs:{"icon":"share","size":"is-small","type":"is-grey-light"}})],1)],2)])])])])]),(_vm.bottom_link)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-2 is-offset-8 pr-5 pt-6 mt-1",staticStyle:{"text-align":"right"}},[_c('br'),_c('br'),_c('br'),_c('div',{staticClass:"bottom-link-div is-clickable",style:({ color: _vm.secondary_color })},[_c('p',{on:{"click":function($event){return _vm.scrollToElement({ behavior: 'smooth' })}}},[_vm._v(_vm._s(_vm.bottom_link))])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }