<template>
	<section class="has-background-primary">
		<div class="home" :class="{ 'vertically-centered ': isMobile }">
			<div class="is-mobile columns is-vcentered">
				<div class="column is-2 is-hidden-touch" style="text-align: center">
					<div class="social_networks ml-6" v-if="socialNetworks">
						<div v-for="social in socialNetworks" :key="social.id" class="columns">
							<div @click="openSocialNetwork(social.attributes.redirect_url)" class="column is-4 is-clickable">
								<b-icon :icon="social.attributes.material_design_icon_alias" size="is-small" type="is-grey-light"> </b-icon>
							</div>
						</div>
						<div class="columns">
							<div @click="shareWebsite()" class="column is-4 is-clickable">
								<b-icon icon="share" size="is-small" type="is-grey-light"> </b-icon>
							</div>
						</div>
					</div>
				</div>
				<div class="column is-8-desktop is-12-touch">
					<div class="column is-offset-2-desktop has-text-right">
						<figure class="back_figure" v-if="$i18n.locale == 'en'">
							<img :src="back_image_en" />
						</figure>
						<figure class="back_figure" v-if="$i18n.locale == 'pt'">
							<img :src="back_image_pt" />
						</figure>
					</div>
					<div class="column info-div" :class="{ 'pl-0 pr-0': !isMobile }">
						<h1 class="info breakable-text">
							{{ front_title }}
							<span
								:style="{
									'border-color': secondary_color,
									'text-decoration': 'underline',
									'text-decoration-color': secondary_color,
									'text-decoration-style': 'solid',
									'text-decoration-thickness': '0.3rem',
									'text-underline-offset': '4px',
								}"
								>{{ underline_text }}</span
							>.
						</h1>
						<div v-if="description" class="mt-6">
							<h2 class="has-text-grey-light has-text-weight-light info-bottom">{{ description }}</h2>
						</div>
						<div class="columns is-mobile is-hidden-desktop mt-6">
							<div class="column is-half is-offset-one-quarter">
								<div class="columns has-text-centered is-mobile">
									<div v-for="social in socialNetworks" :key="social.id" @click="openSocialNetwork(social.attributes.redirect_url)" class="column is-clickable">
										<b-icon :icon="social.attributes.material_design_icon_alias" size="is-small" type="is-grey-light"> </b-icon>
									</div>
									<div @click="shareWebsite()" class="column is-clickable">
										<b-icon icon="share" size="is-small" type="is-grey-light"> </b-icon>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="bottom_link" class="columns">
				<div class="column is-2 is-offset-8 pr-5 pt-6 mt-1" style="text-align: right">
					<br />
					<br />
					<br />
					<div class="bottom-link-div is-clickable" :style="{ color: secondary_color }">
						<p @click="scrollToElement({ behavior: 'smooth' })">{{ bottom_link }}</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	//API
	import { getSocialNewtworks } from '@/api/social-networks';

	export default {
		name: 'Banner',
		props: {
			back_image_en: {
				type: String,
				required: true,
			},
			back_image_pt: {
				type: String,
				required: true,
			},
			front_title: {
				type: String,
				required: true,
			},
			secondary_color: {
				type: String,
				required: true,
			},
			underline_text: {
				type: String,
				required: true,
			},
			bottom_link: {
				type: String,
				//required: true,
			},
			description: {
				type: String,
			},
		},
		data() {
			return {
				socialNetworks: null,
				isMobile: window.innerWidth <= 1023,
			};
		},
		created() {
			getSocialNewtworks()
				.then((response) => {
					if (response.data && response.data.data) {
						this.socialNetworks = response.data.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		methods: {
			openSocialNetwork(link) {
				window.open(link, '_blank');
			},
			scrollToElement(options) {
				this.$emit('childEmit', options);
			},
			shareWebsite() {
				if (navigator.share) {
					navigator
						.share({
							title: 'Formato Verde',
							url: 'https://www.formatoverde.pt/',
						})
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../assets/scss/_variables.scss';

	/*section {
	//background-color: $primary;
	position: relative;
	@media only screen and (min-width: 551px) {
		min-height: 88vh;
	}
	@media only screen and (max-width: 550px) {
		min-height: 95vh;
	}
	@media only screen and (max-width: 400px) {
		min-height: 75vh;
	}
}*/

	section {
		min-height: 88vh;
	}

	.home .info {
		color: var(---grey-light);
		text-align: left;
		//font: normal normal bold 112px/129px Roboto;
		//font-size: 100px;
		font-weight: bold;
		letter-spacing: 0px;
		color: $grey-light;
		white-space: pre-line;
		opacity: 1;

		@media only screen and (min-width: 1130px) {
			font-size: 100px;
		}

		@media only screen and (max-width: 1129px) {
			font-size: 85px;
		}

		@media only screen and (max-width: 950px) {
			font-size: 80px;
		}

		@media only screen and (max-width: 900px) {
			font-size: 75px;
		}

		@media only screen and (max-width: 700px) {
			font-size: 65px;
		}

		@media only screen and (max-width: 570px) {
			font-size: 55px;
		}
		@media only screen and (max-width: 450px) {
			font-size: 50px;
		}
		@media only screen and (max-width: 400px) {
			font-size: 45px;
		}
		@media only screen and (max-width: 350px) {
			font-size: 35px;
		}
	}

	/*.info-div {
	position: absolute;
	top: 250px;
	left: 14%;
	right: 8%;
}*/

	/*.info-div {
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}*/

	.background-text {
		color: $black;
		text-align: left;
		//font-size: 400px;
		letter-spacing: 0px;

		@media only screen and (min-width: 1141px) {
			font-size: 400px;
		}

		@media only screen and (max-width: 1140px) {
			font-size: 350px;
		}

		@media only screen and (max-width: 1020px) {
			font-size: 300px;
		}
	}

	.border-bottom {
		border-bottom: 0.3rem solid;
	}

	/*.bottom-link-div {
	position: absolute;
	bottom: 20px;
	right: 90px;
}*/

	/*.bottom-link-div * {
	color: $lime;
}*/

	.bottom-link-div p::before {
		display: inline-block;
		content: '';
		border-top: 1px solid;
		width: 4rem;
		margin: 0 1rem;
		transform: translateY(-4px);
	}

	.info-bottom {
		@media only screen and (min-width: 1130px) {
			font-size: 50px;
		}

		@media only screen and (max-width: 1129px) {
			font-size: 42.5px;
		}

		@media only screen and (max-width: 950px) {
			font-size: 40px;
		}

		@media only screen and (max-width: 900px) {
			font-size: 37.5px;
		}

		@media only screen and (max-width: 700px) {
			font-size: 32.5px;
		}

		@media only screen and (max-width: 570px) {
			font-size: 27.5px;
		}
		@media only screen and (max-width: 450px) {
			font-size: 25px;
		}
		@media only screen and (max-width: 400px) {
			font-size: 22.5px;
		}
		@media only screen and (max-width: 350px) {
			font-size: 17.5px;
		}
	}

	.back_figure {
		//text-align: right;

		img {
			max-height: 26rem;
		}
	}
</style>
