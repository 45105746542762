<template>
		<video autoplay muted controls controlsList="nodownload">
			<source :src="video_src" type="video/mp4" />
		</video>
</template>

<script>

export default {
	name: 'Video',
    props: {
		video_src: {
			type: String,
			required: true
		},
	}
};
</script>

<style lang="scss" scoped>
/*section {
	min-height: 88vh;
}*/
</style>