<template>
	<section ref="contact_section" class="has-background-primary pb-6" style="position: relative">
		<b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
		<div>
			<img class="full-width" :src="contact_image" />
		</div>
		<div class="columns is-mobile is-vcentered mt-6">
			<div class="column is-8-desktop is-offset-2-desktop is-12-touch">
				<div class="column" :class="{'pl-0 pr-0' : !isMobile}">
					<div class="columns">
						<div style="position: relative" class="column">
							<p class="is-size-1 is-size-3-touch has-text-white has-text-weight-bold"><span class="border-bottom-lime">{{$t('views.HomePage.Contact.underline_text')}}</span></p>
							<br />
							<p class="is-size-3 is-size-5-touch has-text-white has-text-weight-light breakable-text">{{$t('views.HomePage.Contact.secondary_text')}}</p>

							<div style="position: relative; bottom: 0; margin-top: 50px" class="columns info">
								<div class="column">
									<p class="has-text-white has-text-weight-bold mt-2">{{$t('views.HomePage.Contact.address_title')}}</p>
									<p class="has-text-grey-light mt-2">Praça Rainha Dona Amélia, 272</p>
									<p class="has-text-grey-light mt-2">4000-075, Porto, Portugal</p>
								</div>
								<div class="column">
									<p class="has-text-white has-text-weight-bold mt-2">{{$t('views.HomePage.Contact.contacts_title')}}</p>
									<p class="has-text-lime mt-2">info@formatoverde.pt</p>
									<p class="has-text-grey-light mt-2">+351 229 352 231</p>
								</div>
							</div>
						</div>

						<div class="column mt-4">
							<form class="contact-form" @submit.prevent="onSubmit">
								<b-field>
									<b-input v-model="name" :placeholder="$t('views.HomePage.Contact.form_name_placeholder')" required :has-counter="false"></b-input>
								</b-field>

								<b-field>
									<b-input v-model="email" :placeholder="$t('views.HomePage.Contact.form_email_placeholder')" :has-counter="false" type="email" maxlength="30" required> </b-input>
								</b-field>
								<b-field>
									<b-input v-model="message" :placeholder="$t('views.HomePage.Contact.form_message_placeholder')" :has-counter="false" maxlength="200" type="textarea" required></b-input>
								</b-field>
								<b-button
									@click="sendEmail()"
									class="button-border-lime"
									native-type="submit"
									:disabled="isDisabled"
									type="button is-primary"
									style="float: right; border-radius: 0px; border-color: #24f48c"
									>{{$t('views.HomePage.Contact.form_button')}}</b-button
								>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { postMessage } from '@/api/messages';

export default {
	name: 'Contact',
	props: {
		contact_image: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			email: null,
			name: null,
			message: null,
			isLoading: false,
			isFullPage: true,
			isMobile: window.innerWidth <= 1023,
		};
	},
	methods: {
		sendEmail() {
			this.isLoading = true;
			postMessage({
				data: {
					name: this.name,
					email: this.email,
					message: this.message,
				},
			})
				.then((response) => {
					this.$buefy.toast.open({
						message: this.$t('views.HomePage.Contact.form_success_message'),
						type: 'is-success',
						position: 'is-bottom',
					});
					this.email = null;
					this.name = null;
					this.message = null;
					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
					this.$buefy.toast.open({
						duration: 5000,
						message:  this.$t('views.HomePage.Contact.form_error_message'),
						position: 'is-bottom',
						type: 'is-danger',
					});
					console.log(error);
				});
		},
	},
	computed: {
		isDisabled() {
			return !this.email || !this.name || !this.message;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/_variables.scss';

/*section {
	//min-height: 92vh;
	//background-color: $primary;
	position: relative;

	@media only screen and (min-width: 771px) {
		min-height: 115vh;
	}

	@media only screen and (max-width: 770px) {
		min-height: 140vh;
	}
}*/

@media only screen and (min-width: 1024px) {
	section {
		min-height: 105vh;
	}
}



.contact-form textarea,
.contact-form input {
	//background-color: $black;
	border-radius: 0px !important;
}

.button-border-lime {
	border: 1px solid $lime;
}

.border-bottom-lime {
	text-decoration: underline;
	text-decoration-color: $lime;
	text-decoration-style: solid;
	text-decoration-thickness: 0.3rem;
	text-underline-offset: 4px;
}

.full-width {
	width: 100%;
}
</style>