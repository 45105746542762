<template>
	<div>
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
		<Banner
			:front_title="$t('views.HomePage.Banner.front_title')"
			:secondary_color="banner_secondary_color"
			:underline_text="$t('views.HomePage.Banner.underline_text')"
			:bottom_link="$t('views.HomePage.Banner.link_text')"
			:back_image_en="back_image_en"
			:back_image_pt="back_image_pt"
			@childEmit="goToContactDiv"
		>
		</Banner>
		<section class="has-background-primary pt-6" :class="{ 'hero is-fullheight': !isMobile }">
			<Skill @childEmit="goToCategory" v-if="categories" :categories="categories"></Skill>
		</section>
		<section class="has-background-white" :class="{ 'hero is-fullheight': !isMobile }">
			<Work
				@childEmitOpenProject="goToProjectPage"
				@childEmit="goToWorkPage"
				v-if="projects"
				:title="$t('views.HomePage.Work.title')"
				:subtitle="$t('views.HomePage.Work.subtitle')"
				:link_text="$t('views.HomePage.Work.link_text')"
				:projects="projects"
			></Work>
		</section>
		<section class="has-background-white" :class="{ 'hero is-fullheight': !isMobile }">
			<Video v-if="video_src" :video_src="video_src"></Video>
		</section>
		<section class="has-background-primary" :class="{ 'hero is-fullheight': !isMobile }">
			<Ciao></Ciao>
		</section>
		<div ref="contact_div">
			<Contact v-if="contact_image_src" :contact_image="contact_image_src"></Contact>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue';
import Skill from '@/components/Skill.vue';
import Work from '@/components/Work.vue';
import Video from '@/components/Video.vue';
import Ciao from '@/components/Ciao.vue';
import Contact from '@/components/Contact.vue';

//API
import { getProjects } from '@/api/projects';
import { getHomePageSettings } from '@/api/homepage';
import { getCategories } from '@/api/categories';

export default {
	name: 'Home',
	components: {
		Banner,
		Skill,
		Work,
		Video,
		Ciao,
		Contact,
	},
	data() {
		return {
			banner_secondary_color: '#24F48C',
			projects: null,
			video_src: null,
			contact_image_src: null,
			categories: null,
			back_image_en: require('@/assets/img/hello.png'),
			back_image_pt: require('@/assets/img/ola.png'),
			isMobile: window.innerWidth <= 1023,
			isLoading: true,
			request_projects_done: false,
			request_homesettings_done: false,
			request_categories_done: false
		};
	},
	created() {
		this.getData(this.$i18n.locale);
		this.$watch('$i18n.locale', (new_language) => {
			this.isLoading = true;
			this.getData(this.$i18n.locale);
		});
	},
	methods: {
		goToCategory(category_id) {
			this.$router.push({ path: `/category/${category_id}` });
		},
		goToWorkPage() {
			this.$router.push({ path: `/work` });
		},
		goToProjectPage(project_id) {
			this.$router.push({ path: `/project/${project_id}` });
		},
		goToContactDiv(options) {
			const el = this.$refs.contact_div;
			if (el) el.scrollIntoView(options);
		},
		getData(locale) {
			getProjects(locale)
				.then((response) => {
					this.projects = response.data.data.filter((project) => {
						return project.attributes.on_slider && project.attributes.slider_order >= 0;
					});

					this.projects = this.projects.sort((a, b) =>
						a.attributes.slider_order > b.attributes.slider_order ? 1 : b.attributes.slider_order > a.attributes.slider_order ? -1 : 0
					);
					this.request_projects_done = true;
					if (this.request_projects_done && this.request_homesettings_done && this.request_categories_done) this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});

			getHomePageSettings()
				.then((response) => {
					if (response.data.data) {
						if (this.isMobile && response.data.data.attributes.video_mobile.data)
							this.video_src = `${process.env.VUE_APP_API_HOST}${response.data.data.attributes.video_mobile.data.attributes.url}`;
						else if (response.data.data.attributes.video_desktop.data)
							this.video_src = `${process.env.VUE_APP_API_HOST}${response.data.data.attributes.video_desktop.data.attributes.url}`;
					}

					if (response.data.data && response.data.data.attributes.contact_image.data)
						this.contact_image_src = `${process.env.VUE_APP_API_HOST}${response.data.data.attributes.contact_image.data.attributes.url}`;

					this.request_homesettings_done = true;
					if (this.request_projects_done && this.request_homesettings_done && this.request_categories_done) this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});

			getCategories(locale)
				.then((response) => {
					if (response.data.data) {
						this.categories = response.data.data;
						this.categories.map((cat) => {
							cat['is_hover'] = false;
						});
						this.request_categories_done = true;
						if (this.request_projects_done && this.request_homesettings_done && this.request_categories_done) this.isLoading = false;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>
<style lang="scss"  scoped>
</style>
